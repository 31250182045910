.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.neon {
  animation: neon 1s ease infinite;
  -moz-animation: neon 1s ease infinite;
  -webkit-animation: neon 1s ease infinite;
}

@keyframes neon {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

/* 공지팝업 */
.pop01_popup1 {
  position: absolute;
  z-index: 1000000000;
}
.pop01_popup2 {
  position: absolute;
  z-index: 1000000000;
}
.pop01_popup_wrap {
  float: left;
  z-index: 1000000000;
}
.pop01_popup_btn_wrap {
  float: right;
  z-index: 1000000000;
}
.pop01_popup_btn_wrap ul li {
  float: left;
  margin: 0 0 0 5px;
}
.pop01_popup_btn {
  float: right;
  background: #5f2154;
  min-width: 60px;
  height: 36px;
  line-height: 40px;
  padding: 0 15px 0 15px;
  text-align: center;
  display: inline-block;
  font-family: nanumgothic, sans-serif;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}
.pop01_popup_box {
  float: left;
  background: #000000;
  border: 5px solid #a74b95;
  clear: both;
  z-index: 1000000000;
}
.pop01_popup_text {
  float: left;
  width: 100%;
  z-index: 1000000000;
}
.pop01_popup_text h2 {
  font-size: 16px;
}
.pop01_popup_font1 {
  float: left;
  width: 100%;
  font-family: "nanumsquare", sans-serif;
  font-size: 22px;
  letter-spacing: -1px;
  font-weight: 700;
  color: #fff;
  line-height: 40px;
}
.pop01_popup_font2 {
  float: left;
  width: 100%;
  font-family: "nanumgothic", sans-serif;
  font-size: 16px;
  letter-spacing: -1px;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
}

.custom-ui {
  text-align: center;
  width: 600px;
  padding: 20px;
  background: #000000;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui .container {
  text-align: left;
}

.custom-ui > h3 {
  font-size: 160%;
}

.custom-ui > h4 {
  font-size: 150%;
}
.custom-ui > p {
  padding: 5px;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}
